// 移动端首页
<template>
  <div class="m_total" id="mtotal">
    <!-- 置顶top -->
    <Mtop ref="mtop"></Mtop>
    <div v-if="$store.state.islogin">
      <div v-show="playhtml">
        <div class="mplay_title">
          <!-- <img src="../../assets/img/left2.png" @click="YS.routeBack()" alt="返回上层路由" /> -->
          <img src="../../assets/img/return5.png" @click="YS.routeBack()" alt="返回上层路由" />
          <!-- <h1>{{ videolist.title }}</h1> -->
          <h1>{{ titleText || videolist.title }}</h1>
        </div>
        <div class="mplay_player">
          <div
            id="dplayer"
            class="artplayer-app2"
            webkit-playsinline="true"
            playsinline="true"
            x-webkit-airplay="allow"
            x5-playsinline
          >
            <div class="play_load">
              <img v-if="ub_index == 3" width="112" height="43" src="../../assets/u/logo1.png" />
              <img
                v-else-if="ub_index == 2"
                width="112"
                height="43"
                src="../../assets/bo/logo1.png"
              />
              <img v-else width="112" height="43" src="../../assets/img/logo.png" />
              <span class="m_guide">{{ guide }}</span>
            </div>
          </div>
          <div
            id="dplayer3"
            class="artplayer-app3"
            webkit-playsinline="true"
            playsinline="true"
            x-webkit-airplay="allow"
            x5-playsinline
          ></div>
          <div class="play_advertising_time_m" v-if="advertising_ready" @click="advertisingClose">
            <span>
              {{ adver_time }}
            </span>
            <div class="adv_nodes">VIP可关闭广告</div>
            <div class="adv_close"></div>
          </div>
          <div class="pleaseLogin" v-if="!islogin" @click="climustlogin">请先登录</div>
        </div>
        <!-- 广告展示 -->
        <!-- <div
        class="mplay_guanggao"
        v-if="guanggaolist['mobile-play-player']"
        @click="guanggaocli(guanggaolist['mobile-play-player'][0].url)"
      >
        <img
          v-if="guanggaolist['mobile-play-player']"
          :src="guanggaolist['mobile-play-player'][0].source"
          alt="广告位展示位置"
        />
      </div> -->
        <div
          class="mplay_guanggao"
          v-if="guanggaolist['mobile-play-player-below']"
          @click="guanggaocli(guanggaolist['mobile-play-player-below'][0].url)"
        >
          <img
            v-if="guanggaolist['mobile-play-player-below']"
            :src="guanggaolist['mobile-play-player-below'][0].source"
            alt="广告位展示位置"
          />
        </div>
        <div class="mplay_epi-top">
          <!-- 点赞 -->
          <div class="mer_list-top">
            <div
              class="mer_1"
              @click="clieva(1)"
              :class="evaluate_type > 0 ? 'mer_1_2' : 'mer_1_1'"
            ></div>
            <a :class="evaluate_type > 0 ? 'mer_2-act' : 'mer_2-unact'">
              {{ videolist.appreciate >= 0 ? videolist.appreciate : '...' }}
            </a>
          </div>
          <!-- 点踩 -->
          <div class="mer_list-top">
            <div
              class="mer_4"
              @click="clieva(-1)"
              :class="evaluate_type < 0 ? 'mer_4_2' : 'mer_4_1'"
            ></div>
            <a :class="evaluate_type < 0 ? 'mer_2-act' : 'mer_2-unact'">
              {{ videolist.depreciate >= 0 ? videolist.depreciate : '...' }}
            </a>
          </div>
          <!-- 收藏 -->
          <div class="mer_list-top">
            <div
              class="mer_2"
              :class="in_favorite ? 'mer_2_2' : 'mer_2_1'"
              @click="clifavorite"
            ></div>
            <a :class="in_favorite ? 'mer_2-act' : 'mer_2-unact'">收藏</a>
          </div>
          <!-- 分享 -->
          <div class="mer_list-top">
            <div class="mer_5 mer_5_1" @click="clishare"></div>
            <a>分享</a>
          </div>
          <!-- 错误反馈 -->
          <div class="mer_list-top" @click="clifeedback">
            <div class="mer_0"></div>
            <a>反馈</a>
          </div>
          <!-- <div class="mer_list mer_3"></div> -->
        </div>
        <!-- 简介 -->
        <div class="mplay_epi">
          <div class="mplay_epi-left" @click="clickxq">
            <a>主演/导演/剧情简介</a>
            <div :class="{ p_180: p_d }"></div>
          </div>
        </div>
        <!-- 简介介绍 -->
        <transition name="mcenter_9">
          <div
            class="mp_description"
            @click="detailedShow = !detailedShow"
            :class="detailedShow ? 'mplay_detailed' : 'mplay_detailed_un'"
            v-show="p_d"
          >
            <img
              :src="videolist.pic ? videolist.pic : ''"
              :onerror="$store.state.defaultimg"
              alt="视频简介介绍下拉开关"
            />
            <div class="p_descrightm">
              <span class="p_dec_1 none_enter_line">
                导演：
                <span class="p_dec_2 lin1">{{ videolist.director }}</span>
              </span>
              <span class="p_dec_1 none_enter_line">
                主演：
                <span class="p_dec_2 lin1">{{ videolist.starring }}</span>
              </span>
              <h2 class="p_dec_1-m">
                简介：
                <span class="p_dec_2-m">{{ videolist.description }}</span>
              </h2>
            </div>
          </div>
        </transition>

        <!-- 清晰度 -->
        <transition name="mplay_clear">
          <div class="mplay_clear" v-show="mplay_clear_isok || minimalistReady">
            <!-- 选择的样式： mplay_c_box-act -->
            <div
              class="mplay_c_box"
              :class="{
                'mplay_c_box-act-blue':
                  item.name == qualityName && item.name != '超清·720P' && item.name != '蓝光·1080P',
                'mplay_c_box-act':
                  item.name == qualityName && item.name != '标清·360P' && item.name != '高清·480P',
                'q__1q__ clear_1': options.video.quality[index].name == '标清·360P',
                'q__2q__ clear_2': options.video.quality[index].name == '高清·480P',
                'q__3q__ clear_3': options.video.quality[index].name == '超清·720P',
                'q__4q__ clear_4': options.video.quality[index].name == '蓝光·1080P',
              }"
              v-for="(item, index) in options.video.quality"
              :key="index"
              :data-index="index"
              @click="minimalist ? cliquality($event, index) : changequality($event, index)"
            >
              <!-- {{ item }} -->
              <span v-show="qua[1] != item.name && qua[2] != item.name" class="mplay_cb_vip">
                VIP会员
                <div class="clear_vip_e"></div>
              </span>
              <span v-show="qua[2] == item.name" class="mplay_cb_novip">VIP会员</span>
              <span v-show="qua[1] == item.name" class="mplay_cb_novip">VIP会员</span>
              <a>{{ item.name }}</a>
            </div>
          </div>
        </transition>
        <!-- 选集 -->
        <div class="mplay_list">
          <div class="mplay_list_title">
            <!-- video_fragment_title -->
            <a
              class="mplay_lt_list"
              :class="{ 'mplay_lt_list-act': ltindex == index }"
              @click="cliSettitle(index)"
              v-for="(item, index) in epi"
              :key="index"
            >
              {{ item }}
            </a>
          </div>
          <div class="mplay_list_body" v-if="video_fragment_list">
            <a
              class="mplay_list_body_a"
              :class="item.act ? 'mplay_list_body_a-act' : {}"
              @click="clivideoinfo(item.id)"
              v-for="(item, index) in video_fragment_list.slice(ltindex * 20, (ltindex + 1) * 20)"
              :key="index"
            >
              {{ item.symbol }}
              <img
                src="../../assets/img/play-ing-bule.gif"
                v-if="item.act"
                alt="当前播放集数样式动图"
              />
            </a>
          </div>
        </div>

        <!-- 评论区 -->
        <div class="mplay_comm">
          <!-- 标题头 -->
          <div class="mplay_comm_title">
            <a>评论区</a>
            <div @click="clipinglun">
              <img src="../../assets/img/mplay_4.png" alt="评论图标" />
              <a>评论</a>
            </div>
          </div>
          <!-- 评论框 -->
          <div class="pinglun" v-show="pldialog">
            <div class="pinglun_title">
              <a @click="pldialog = false">取消</a>
              <a @click="videopostComment">发表评论</a>
            </div>
            <textarea
              maxlength="140"
              v-model="textcontent"
              placeholder="请在此处发表评论
注意，以下行为将被封号：严重剧透、发布广告、木马链接、宣传同类网站、辱骂工作人员等。"
            ></textarea>
          </div>
          <!-- 评论列表 -->

          <div class="mplay_comm_list" v-for="(item, index) in video_comment_list" :key="index">
            <div class="mplay_comm-left">
              <img
                :src="item.user_avatar ? item.user_avatar : ''"
                :onerror="$store.state.defaulava"
                alt="评论用户头像"
              />
            </div>
            <div class="mplay_comm-right">
              <a>{{ item.user_nickname }}</a>
              <span>
                {{ item.content }}
              </span>
            </div>
          </div>
        </div>
      </div>
      <!-- WIN 需要开通会员 -->
      <div class="mpayresult" v-show="mustvip">
        <div class="payrbody7">
          <img src="../../assets/img/mustvip.png" alt="需要会员提示图片" />
          <span>非常抱歉，本功能需要VIP会员才能体验哦！</span>
        </div>
        <div class="payrbody2">
          <!-- <a class="actthen">您还可以选择：</a> -->
          <div class="payrbodyflex2">
            <a class="payb_1" @click="openandclo('mustvip', false)">狠心离开</a>
            <a class="payb_2" @click="climustvip">立即购买</a>
          </div>
        </div>

        <img
          class="login-close"
          @click="openandclo('mustvip', false)"
          src="../../assets/img/close.png"
          alt="关闭需要会员弹窗"
        />
      </div>
      <!-- WIN 需要登录才能高清 -->
      <div class="mpayresult" v-show="mustlogin">
        <div class="payrbody7">
          <img src="../../assets/img/mustvip.png" alt="需要登录用户提示图片" />
          <span>非常抱歉，本功能需要登录用户才能体验哦！</span>
        </div>
        <div class="payrbody2">
          <div class="payrbodyflex2">
            <a class="payb_1" @click="openandclo('mustlogin', false)">返回</a>
            <a class="payb_2" @click="climustlogin">前往登录</a>
          </div>
        </div>
        <img
          class="login-close"
          @click="openandclo('mustlogin', false)"
          src="../../assets/img/close.png"
          alt="关闭需要用户登录弹窗"
        />
      </div>
      <!-- WIN 视频报错反馈 -->
      <transition name="mcenter_8">
        <div class="mpayresult1 mpayresult_error" v-show="playerror">
          <!-- <div class="mpayresult1" v-show="true"> -->
          <div class="payrbody11">
            <textarea
              maxlength="255"
              v-model="playerrorText"
              placeholder="视频还在尝试加载中，您还可以向我们反馈。请描述您遇到的问题（可留空）"
            ></textarea>
          </div>
          <div class="payrbody12">
            <span @click="cliplayerror">确认</span>
          </div>
          <img
            class="login-close"
            @click="openandclo('playerror', false)"
            src="../../assets/img/close.png"
            alt="关闭视频报错弹窗"
          />
        </div>
      </transition>
      <!-- WIN 公用弹窗成功 -->
      <transition name="mcenter_8">
        <div class="mpayresult1" v-show="publicDialogshow">
          <!-- <div class="mpayresult1" v-show="true"> -->
          <div class="payrbody11">
            <img src="../../assets/img/pass_ok.png" alt="成功弹窗提醒的主要提示图片" />
            <span>{{ publicDialog.title }}</span>
          </div>
          <div class="payrbody12">
            <span @click="openandclo('publicDialogshow', false)">确认</span>
          </div>
          <img
            class="login-close"
            @click="openandclo('publicDialogshow', false)"
            src="../../assets/img/close.png"
            alt="关闭成功提示弹窗"
          />
        </div>
      </transition>

      <!-- WIN 公用失败 -->
      <transition name="mcenter_8">
        <div class="mpayresult2" v-show="publicDialogshowError">
          <!-- <div class="mpayresult2" v-show="true"> -->
          <div class="payrbody3">
            <a class="mvip_fail">{{ publicDialogError.title }}</a>
            <img class="marb" src="../../assets/img/mvip_fail.png" alt="失败弹窗的主要提示图片" />
          </div>
          <div class="payafter">
            <!-- <span>取消</span> -->
            <span @click="openandclo('publicDialogshowError', false)">返回重试</span>
          </div>

          <img
            class="login-close"
            @click="openandclo('publicDialogshowError', false)"
            src="../../assets/img/close.png"
            alt="关闭失败弹窗"
          />
        </div>
      </transition>
      <!-- WIN 分享弹窗 -->
      <transition name="mcenter_8">
        <div class="mpayresult-share" v-show="shareShow">
          <span class="share_m-span">长按图片保存二维码</span>
          <div class="share_m-bottom">
            <vue-qr
              :text="shareUrlErwm"
              :size="120"
              :logoScale="0.25"
              :logoSrc="qrLogo"
              :margin="10"
              :logoMargin="3"
              :logoCornerRadius="1"
              :correctLevel="3"
            ></vue-qr>
          </div>
          <div class="share_m-tile">
            <a
              v-clipboard:copy="wechatUrl()"
              v-clipboard:success="onCopy"
              v-clipboard:error="onError"
            >
              <img src="../../assets/img/share_wechat.png" />
              <span>微信</span>
            </a>
            <a :href="shareList.weibo" target="_blank">
              <img src="../../assets/img/share_weibo.png" />
              <span>微博</span>
            </a>
            <a :href="shareList.qq" target="_blank">
              <img src="../../assets/img/share_qq.png" />
              <span>QQ</span>
            </a>

            <a :href="shareList.twitter" target="_blank">
              <img src="../../assets/img/share_twitter.png" />
              <span>twitter</span>
            </a>
            <a :href="shareList.facebook" target="_blank">
              <img src="../../assets/img/share_facebook.png" />
              <span>facebook</span>
            </a>
          </div>
          <div class="share_m-input-box">
            <input v-model="shareUrl" @focus="getInputFocus($event)" />
            <button
              v-clipboard:copy="shareUrl"
              v-clipboard:success="onCopy_ok"
              v-clipboard:error="onError"
              id="shareInput"
              class="play_share-copy-m"
            >
              复制
            </button>
          </div>
          <img
            class="login-close"
            @click="openandclo('shareShow', false)"
            src="../../assets/img/close.png"
            alt="关闭失败弹窗"
          />
        </div>
      </transition>
      <!-- 遮罩层 -->
      <div id="coverm"></div>
      <Mbottom v-show="false"></Mbottom>
    </div>
  </div>
</template>
<script>
let that;
import Mtop from '../../components/moblie/mTop';
import Mbottom from '../../components/moblie/mBottom';
// import dplayer from 'dplayer'; //播放器插件
import Hls from 'cdnbye';
import Artplayer from 'artplayer'; //播放器插件
import artplayerPluginDanmuku from 'artplayer-plugin-danmuku';
import vueQr from 'vue-qr';
// import Hls from 'hls.js';
export default {
  //inject: ['routerRefresh'], //在子组件中注入在父组件中创建的属性
  components: {
    Mtop,
    Mbottom,
    vueQr,
  },
  name: 'mHome',
  computed: {
    isvip: () => {
      return that.$store.state.isvip;
    },

    islogin: () => {
      return that.$store.state.islogin;
    },
  },
  data() {
    return {
      ub_index: 1,
      oneSecond: false,
      minimalist: true, //极简模式，开启则没有任何功能UI
      detailedShow: true, //是否显示资源的详细信息（演员、简介）
      auto_adv: true, //是否直接跳过广告
      minimalistReady: false, //极简模式的载入视频的状态
      p_d: false, //是否打开内容介绍
      advertising_video_m: {},
      advertising_video_playing: false,
      qrLogo: require('../../assets/img/icon1.png'),
      shareList: {
        weibo: '',
        qq: '',
        twitter: '',
        facebook: '',
      },
      shareShow: false,
      shareUrlErwm: 'https://www.uvod.tv',
      shareUrl: 'https://www.uvod.tv',
      danmuku: '',
      danmuCode: true,
      advertising_loading: false,
      advertising_video: {}, //广告播放器实例
      adver_time: null,
      advertising_ready: false,
      puaseNum: 0, //自动暂停接口次数
      puaseCurrrent: 0, //记录上次暂停的时间
      setpause: null,
      autoErrorReport: false, //自动报错弹出
      set2: {},
      titleText: '', //标题文本
      guide: '（1/3）准备中，即将获取该视频信息...', //引导提示文字消息
      guideError: '',
      lastListCode: true, //防止重复弹出已经是最后一集的提示
      nowVideoIndex: null, //当前播放在第几个
      cangetqua: true, //是否获取视频播放资源地址
      canplayqi: null,
      qualityName: '标清·360P', //当前选择视频的清晰度
      playerrorText: '', //视频错误反馈内容
      playerror: false, //视频报错反馈
      subtimechange: null,
      playhtml: true, //整个页面是否显示隐藏
      mplay_clear_isok: false, //清晰度div显示
      jumpover: false, //是否跳过片头片尾
      jumpzimu: true, //字幕开关
      locitem: {}, //本地存储信息
      video_soruce: {}, //本地存储的视频清晰度/字幕/片头片尾信息
      subact: '', //当前字幕选择的标识
      subtitles: [], //字幕列表
      highlight: [], //进度条样式
      qact: 0, //清晰度选择下标
      videodom: {},
      isAndroid: false,
      isiOS: false,
      hls: {},
      mustvip: false,
      mustlogin: false,
      ltindex: 0,

      num: 1,
      dpdata: {},
      playbutton: {}, //播放按钮
      textcontent: '', //评论内容
      in_favorite: null, // 是否已收藏[0=否 1=是]
      in_evaluate: null, // 是否已评价(赞或踩)[0=否 1=是]
      evaluate_type: null,
      video_comment_list: [], //评论列表
      video_comment_total: null, //评论总数
      total_epi: 68,
      video: {},
      playIcon: {},
      playnow: false,
      player: null,
      videolist: {}, //当前视频信息存储
      video_fragment_title: [],
      video_fragment_list: [], // 分集列表
      video_fragmen_act: 0, //当前选中的集数列表
      adver_dialog: false, //广告弹窗
      pldialog: false, //打开评论窗口
      guanggaolist: [], //广告信息
      epi: [],
      epilist: [],
      publicDialogshow: false,
      publicDialog: {
        title: '', //标题
        type: '', //弹窗的类型
      },
      publicDialogshowError: false,
      publicDialogError: {
        title: '', //标题
        type: '', //弹窗的类型
      },
      //接收参数
      videonews: {
        video_id: null, //获取视频的id变量
        video_fragment_id: null, //获取视频的子集id变量
        quality: null, //清晰度
        seek: null, //跳转的时间
      },
      options: {
        video: {
          type: 'auto',
          quality: [],
          defaultQuality: 0,
        },
        subtitle: {
          url: '',
          // type: 'webvtt',
          fontSize: '25px',
          bottom: '10%',
          color: '#b7daff',
          // color: 'white',
        },
        contextmenu: [{}],
        pluginOptions: {
          hls: {
            // hls config
          },
        },
      },
      // 后端支持的清晰度[1=标清 2=高清 3=超清 4=蓝光]
      qua: [null, '标清·360P', '高清·480P', '超清·720P', '蓝光·1080P'],
      qua1: [null, '标清', '高清', '超清', '蓝光'],
      dp: {},
      //p2p测试
      totalP2PDownloaded: null,
      totalP2PUploaded: null,
      totalHTTPDownloaded: null,
    };
  },
  created() {
    that = this;
    this.ub_index = window.g.ub_index;
    //var ismob = /Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent);
    if (!that.YS.ismob()) {
      //移动端地址
      that.YS.routetogo({
        path:
          window.g.ub_index == 2
            ? window.g.WEB_URL_BO
            : window.g.ub_index == 3
            ? window.g.WEB_URL_U
            : window.g.WEB_URL + '/play',
        query: that.$route.query,
      });
      // window.location.href = window.g.WEB_URL + '/play.html';
    }
    that.$store.commit('changelogin');
    that.$store.commit('getisvipCode');
    that.videonews.video_id = this.$route.query.video_id ? this.$route.query.video_id : 1;
    //分集判断1
    this.videonews.video_fragment_id = this.$route.query.lid ? this.$route.query.lid : null;
    this.videonews.quality = this.$route.query.quality ? this.$route.query.quality : 1; //清晰度选择初始化
    that.qualityName = that.qua[that.videonews.quality];
    that.options.video.defaultQuality = this.videonews.quality - 1;
    //分享连接生成
    if (this.$route.query.video_id) {
      that.shareUrl =
        window.location.origin +
        window.location.pathname +
        '?video_id=' +
        this.$route.query.video_id;
      if (this.$route.query.lid) that.shareUrl = that.shareUrl + '&lid=' + this.$route.query.lid;
    }
    that.shareUrlErwm = that.shareUrl;

    this.videonews.seek = this.$route.query.seek ? this.$route.query.seek : null;
    var u = navigator.userAgent;
    // debugger;
    that.isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; //android终端
    that.isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
    that.locitem = JSON.parse(localStorage.getItem('artplayer_settings')); //获取本地存储信息
    if (that.locitem) {
      if (that.locitem.danmuCode == false) that.danmuCode = that.locitem.danmuCode;
    }
    that.getAndCheckquer(); //开局获取并检查校验清晰度
  },
  beforeMount() {
    that.getvideoinfo();
  },
  mounted() {
    that.$store.commit('changelogin');
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
    // var isMobile = !!navigator.userAgent.match(/AppleWebKit.*Mobile.*/); //是否手机端
    //大多数移动端浏览器禁止了视频自动播放。

    // that.YS.actgo('mplay_c_box', 'mplay_c_box-act', 2);

    that.videocomment(); //获取评论列表
    that.adall('mobile-play-player'); //获取广告
    that.adall('mobile-play-player-below'); //获取广告
  },
  methods: {
    piptest() {
      // alert(document.pictureInPictureEnabled);
      // debugger;
      const video = document.getElementsByClassName('art-video')[0];
      // debugger;
      video.requestPictureInPicture();
    },
    //返回微信分享连接
    wechatUrl() {
      return that.titleText + '\n' + that.shareUrlErwm;
    },
    //分享微信成功
    onCopy() {
      that.$refs.mtop.messTop = '复制成功!打开微信粘贴给好友吧!';
      that.$refs.mtop.mess_show = true;
      setTimeout(() => {
        that.$refs.mtop.mess_show = false;
      }, 3000);
    },
    //分享成功
    onCopy_ok() {
      that.$refs.mtop.messTop = '复制成功!';
      that.$refs.mtop.mess_show = true;
      setTimeout(() => {
        that.$refs.mtop.mess_show = false;
      }, 3000);
    },
    //分享失败
    onError() {
      that.$refs.mtop.messTop = '复制失败!';
      that.$refs.mtop.mess_show = true;
      setTimeout(() => {
        that.$refs.mtop.mess_show = false;
      }, 3000);
    },
    //点击微信分享
    shareWechat() {},
    //生成分享连接地址
    changeShareList() {
      that.shareList.weibo =
        'http://service.weibo.com/share/share.php?url=' +
        that.shareUrlErwm +
        '&title=' +
        that.titleText +
        '&pic=' +
        that.videolist.pic;
      that.shareList.qq =
        'https://connect.qq.com/widget/shareqq/index.html?url=' +
        that.shareUrlErwm +
        '&title=' +
        that.titleText +
        '&pic=' +
        that.videolist.pic;
      that.shareList.twitter =
        'https://twitter.com/intent/tweet?text=' +
        that.titleText +
        that.shareUrlErwm +
        '&pic=' +
        that.videolist.pic;
      that.shareList.facebook =
        'https://www.facebook.com/share.php?u=' + that.shareUrlErwm + '&t=' + that.titleText;
      // 'https://www.facebook.com/share.php?t=' + that.titleText + '&u=' + that.shareUrlErwm;
      // FB.ui(
      //   {
      //     method: 'share',
      //     href: that.shareUrlErwm, //这里换成你的网址
      //   },
      //   function (response) {
      //     //分享回调
      //   },
      // );
    },
    //点击分享
    async clishare() {
      try {
        // that.shareShow = true;
        that.openandclo('shareShow', true);
      } catch (error) {
        console.log(error.message);
      }
    },
    //使用公共弹窗
    usepubilDialog(title) {
      that.publicDialog.title = title;
      // that.publicDialog.type = type;
      var coverm = document.querySelector('#coverm');
      if (coverm) {
        coverm.style.display = 'block';
        this.$nextTick(() => {
          that.publicDialogshow = true;
        });
      }
    },
    //使用公共弹窗，失败
    usepubilDialogError(title, type) {
      // that.pubErrortype = type;
      that.publicDialogError.title = title;
      that.publicDialogError.type = type;

      var coverm = document.querySelector('#coverm');
      coverm.style.display = 'block';
      this.$nextTick(() => {
        that.publicDialogshowError = true;
      });
    },
    //提交视频报错反馈
    async cliplayerror() {
      try {
        var obj = {};
        obj.video_id = that.videonews.video_id;
        obj.video_fragment_id = that.videonews.video_fragment_id;
        obj.content = that.playerrorText;
        obj.client_type = 2; //移动客户端
        obj.os = that.YS.getOS().name + '/' + that.YS.getOS().versionStr; //系统
        obj.screen_width = document.documentElement.clientWidth || document.body.clientWidth;
        obj.screen_height = document.documentElement.clientHeight || document.body.clientHeight;
        obj.browser = that.YS.getBrowserInfo()[0] || that.YS.getBrowserInfo() || '';
        obj.remarks = that.guide + that.guideError;
        obj.quality = that.videonews.quality;
        await that.api.user.api_videoreport(obj);
        that.openandclo('playerror', false);
        that.usepubilDialog('反馈成功，感谢您的支持');
      } catch (error) {
        that.openandclo('playerror', false);
        that.usepubilDialogError(error.message, '');
      }
    },
    // 点击打开视频反馈
    clifeedback() {
      // that.openandclo('playerror', true);

      if (that.$store.state.islogin) {
        //已登录
        that.openandclo('playerror', true);
      } else {
        //未登录
        that.$store.commit('addloginBefore', () => {
          that.openandclo('playerror', true);
        });
        that.openandclo('mustlogin', true);
      }

      // localStorage.setItem('feedbackROW',true)
    },
    //发送视频反馈内容

    // 点击点赞点踩
    clieva(type) {
      // if (!that.in_evaluate) {
      //   that.api.user
      //     .api_videoevaluate({ video_id: that.videonews.video_id, evaluate: type })
      //     .then(() => {
      //       if (type > 0) {
      //         //点赞
      //         that.videolist.appreciate += 1;
      //         that.evaluate_type = 1;
      //       } else {
      //         //反之点踩
      //         that.videolist.depreciate += 1;
      //         that.evaluate_type = -1;
      //       }
      //     });
      // }
      if (that.$store.state.islogin) {
        //已登录
        that.getvaluate(type);
      } else {
        //未登录
        that.openandclo('mustlogin', true);
        // that.$store.commit('addloginBefore', () => {
        //   that.getvaluate(type);
        // });
        // that.YS.routetogo({ path: '/mlogin' });
      }
    },
    //点赞点踩接口
    async getvaluate(type) {
      try {
        if (!that.in_evaluate) {
          that.api.user
            .api_videoevaluate({ video_id: that.videonews.video_id, evaluate: type })
            .then(() => {
              if (type > 0) {
                //点赞
                that.videolist.appreciate += 1;
                that.evaluate_type = 1;
              } else {
                //反之点踩
                that.videolist.depreciate += 1;
                that.evaluate_type = -1;
              }
              that.usepubilDialog('评价成功');
            });
        } else {
          that.usepubilDialogError('当前已评价，请勿重复操作', '');
        }
      } catch (error) {
        that.usepubilDialogError(error.message, '');
      }
    },
    // 计算集数
    calepi() {
      var num = Math.ceil(that.total_epi / 20);
      if (num >= 1) {
        // var num2 = num - 1;
        for (let i = 1; i < num; i++) {
          that.epi.push((i - 1) * 20 + 1 + '-' + i * 20);
        }
        that.epi.push(that.epi.length * 20 + 1 + '-' + that.total_epi);
      }
    },
    //切换集数列表
    cliSettitle(index) {
      that.video_fragmen_act = index;
      that.ltindex = index;
      // that.YS.actgo('mplay_lt_list', 'mplay_lt_list-act', index);
    },
    // 切换集
    clivideoinfo(id) {
      that.YS.routetogo({
        path: '/mplay',
        query: { video_id: this.videonews.video_id, lid: id },
      });
      //this.routerRefresh(); //调用app.vue里面的routerRefresh()方法，完成摧毁和重建过程
    },
    //获取视频信息
    getvideoinfo() {
      that.api.user
        .api_videoinfo({ id: this.videonews.video_id })
        .then((data) => {
          that.guide = '（2/3）获取信息成功！即将获取资源...';

          that.videolist = data.video;
          that.in_favorite = data.in_favorite;
          that.in_evaluate = data.in_evaluate;
          that.evaluate_type = data.evaluate_type;

          that.video_fragment_list = data.video_fragment_list;
          if (that.video_fragment_list) {
            that.total_epi = data.video_fragment_list.length;
            var num = Math.ceil(data.video_fragment_list.length / 20);
            if (num >= 1) {
              // var num2 = num - 1;
              for (let i = 1; i < num; i++) {
                that.video_fragment_title.push((i - 1) * 20 + 1 + '-' + i * 20);
              }
              that.video_fragment_title.push(
                that.video_fragment_title.length * 20 + 1 + '-' + data.video_fragment_list.length,
              );
            }
            that.calepi();
            that.titleText = data.video.title;
            let fraText = '';
            //分集判断2，如果获取不到地址栏的就取第一个
            if (!that.videonews.video_fragment_id) {
              that.videonews.video_fragment_id = that.video_fragment_list[0].id;
              that.options.video.quality = that.video_fragment_list[0].qualities;
              if (that.video_fragment_list.length > 1) {
                that.titleText = that.titleText + ' 第' + that.video_fragment_list[0].symbol + '集';
                fraText = ' 第' + that.video_fragment_list[0].symbol + '集';
              }
            } else {
              //获取到了地址栏的
              that.video_fragment_list.forEach((e) => {
                if (e.id == that.videonews.video_fragment_id) {
                  that.options.video.quality = e.qualities;
                  if (that.video_fragment_list.length > 1) {
                    that.titleText = that.titleText + ' 第' + e.symbol + '集';
                    fraText = ' 第' + e.symbol + '集';
                  }
                }
              });
            }
            document.getElementsByTagName('title')[0].innerText =
              '《' +
              data.video.title +
              '》' +
              fraText +
              (this.ub_index == 2
                ? '-在线观看-BO影视'
                : this.ub_index == 3
                ? '-在线观看-U影视'
                : '-在线观看-UB影视');
            //这里获取完需要播放的分集了,下面需要校验不存在用户的清晰度时（非vip想要进入1080p，拦截掉）
            // that.videonews.quality
            if (that.options.video.quality.indexOf(parseInt(that.videonews.quality)) < 0) {
              //不存在时
              for (var i = 4; i > 0; i--) {
                if (that.options.video.quality.indexOf(i) >= 0) {
                  //匹配到了,把可以放的清晰度拿出来
                  //此时获取到了清晰度了，再做权限校验
                  // console.log(i + '存在');
                  that.canplayqi = i;
                  that.cangetqua = true;

                  break;
                }
              }
            } else {
              that.canplayqi = that.videonews.quality;
            }
            that.getdanmuku(); //获取弹幕，需要放在获取分集ID的后面
            that.changeShareList(); //生成分享连接
            //保险的标清
            //清晰度提示
            if (!that.canplayqi) {
              if (that.options.video.quality.indexOf(1) >= 0) {
                that.canplayqi = 1;
              } else if (that.options.video.quality.indexOf(2) >= 0) {
                that.usepubilDialogError('该视频需要登录用户才能播放，请前往登录', '');
                that.openandclo('mustlogin', true);
              }
              // else if (
              //   that.options.video.quality.indexOf(3) >= 0 ||
              //   that.options.video.quality.indexOf(4) >= 0
              // ) {
              //   that.usepubilDialogError(
              //     '该视频仅支持超清/蓝光清晰度，至少需要VIP用户能播放，请先开通vip',
              //     '',
              //   );
              //   clearInterval(that.set2);
              //   that.openandclo('mustvip', true);
              // }
              // if (!that.canplayqi) {
              //   that.canplayqi = 1;
              // }
            }
            if (that.canplayqi) {
              that.videonews.quality = that.canplayqi;
              that.qualityName = that.qua[that.videonews.quality];
              if (!that.islogin) {
                return that.openandclo('mustlogin', true);
              }
              //获取清晰度
              that.api.user
                .api_videosource(that.videonews)
                .then((data) => {
                  if (!data.video_soruce.url) that.guideError += '视频url出错！';
                  that.guide = '（3/3）获取资源成功！即将载入播放...';
                  if (!that.auto_adv) {
                    let advertising_video_m = new Artplayer({
                      container: '.artplayer-app3',
                      whitelist: ['*'],
                      // autoplay: true,
                      autoplay: true,

                      moreVideoAttr: {
                        'webkit-playsinline': true,
                        playsInline: true,
                        // autoplay: true,
                      },
                      loop: false,
                      setting: false,
                      hotkey: false,
                      isLive: true,
                      control: false,
                      // url: require('../assets/text/textplay.mp4'),
                      // url: 'https://15-cdn.savingbox.me/content/zy-qcynd3j-20210217.mp4/index.m3u8',
                      customType: {
                        m3u8: (video) => {
                          var hls = new Hls(that.p2p);
                          hls.loadSource(that.url);
                          hls.attachMedia(video);
                        },
                      },
                      url: require('../../assets/text/guanggao.mp4'),
                    });
                    let adv_video = document.getElementsByClassName('artplayer-app3')[0];
                    adv_video.addEventListener('click', ($event) => {
                      if (
                        $event.target.nodeName == 'path' ||
                        $event.target.nodeName == 'svg' ||
                        $event.target.nodeName == 'I'
                      ) {
                        return;
                      }
                      // art-controls-left
                      let videooo = adv_video.querySelector('video');
                      if (!videooo) return;
                      if (!that.advertising_video_playing) {
                        videooo.play();
                        that.advertising_video_playing = true;
                      } else {
                        videooo.play();
                        window.open('https://www.baidu.com');
                      }

                      // debugger;
                      // if (advertising_video_m.player.play != undefined) {
                      //   advertising_video_m.player.play = true;
                      // } else if (advertising_video_m.pause) {
                      //   advertising_video_m.pause = false;
                      // }
                      // advertising_video_m.player.playing = true;
                    });

                    that.advertising_video = advertising_video_m;
                    advertising_video_m.on('ready', () => {
                      that.advertising_ready = true;
                      // var video = document.getElementsByClassName('art-video');
                      // video[0].muted = false;
                      sessionStorage.setItem('repeatedly', 1);
                      // advertising_video_m.mask.show = false;
                      // setTimeout(() => {
                      //   var video = document.getElementsByClassName('art-video');
                      //   video[0].muted = false;
                      //   var soundbutton = document.getElementsByClassName('art-control-volume');
                      //   soundbutton[0].click();
                      //   that.advertising_video.player.volume = 1;
                      // }, 1000);
                      that.set_ad = setInterval(() => {
                        that.adver_time =
                          Math.floor(that.advertising_video.player.duration) -
                          Math.floor(that.advertising_video.player.currentTime);
                      }, 500);
                    }),
                      // advertising_video_m.on('pause', () => {
                      //   window.open('https://www.baidu.com');
                      //   advertising_video_m.player.play = true;
                      // });
                      advertising_video_m.on('video:ended', function () {
                        let adv_video_just = document
                          .getElementsByClassName('artplayer-app2')[0]
                          .querySelector('video');
                        // debugger;
                        adv_video_just.currentTime = 0;
                        adv_video_just.play();
                        that.advertising_ready = false;
                        // clearInterval(that.set_ad);
                        advertising_video_m.destroy();
                        that.advertising_loading = true;
                        //非极简模式直接打开播放，极简模式下会在hls.on中打开播放
                        // that.dp.player.play = true;
                        //  if (!that.minimalist) {
                        //     that.dp.player.play = true;
                        //   }
                      });
                  }

                  var set1 = setInterval(function () {
                    if (
                      that.options.video.quality[0] &&
                      that.url !== null &&
                      that.url !== undefined
                    ) {
                      clearInterval(set1);
                      let dp = new Artplayer({
                        // container: document.getElementById('dplayer'),
                        container: '.artplayer-app2',
                        url: that.url,
                        // theme: '#149BEC',
                        // url: 'https://artplayer.org/assets/sample/video.mp4',

                        // url: 'https://artplayer.org/assets/sample/video.mp4',
                        video: that.options.video,
                        subtitle: {
                          url: that.options.subtitle.url === null ? '' : that.options.subtitle.url,
                        },
                        // autoMini: true,
                        whitelist: that.minimalist ? [] : ['*'],
                        highlight: that.highlight, //进度条
                        autoplay: that.auto_adv ? true : false,
                        quality: that.options.video.quality,
                        subtitleOffset: true,
                        setting: true,
                        loop: false,
                        aspectRatio: true,
                        pip: true,
                        fullscreen: true,
                        // fullscreenRotate: true,
                        // airplay: true,
                        // hotkey: true, // 移动端全屏时向右划动快进，向左划动快退。
                        moreVideoAttr: {
                          crossOrigin: 'anonymous',
                          playsInline: true,
                        },
                        //自定义添加
                        controls: [
                          {
                            name: 'backR',
                            position: 'right',
                            index: 15,
                            html: '倍数',
                            selector: [
                              {
                                name: '2倍',
                                type: 2,
                                index: 0,
                              },
                              {
                                name: '1.5倍',
                                type: 1.5,
                                index: 1,
                              },
                              {
                                name: '1.25倍',
                                type: 1.25,
                                index: 2,
                              },
                              {
                                name: '正常',
                                type: 1,
                                index: 3,
                              },
                              {
                                name: '0.75倍',
                                type: 0.75,
                                index: 4,
                              },
                              {
                                name: '0.5倍',
                                type: 0.5,
                                index: 5,
                              },
                            ],
                            onSelect: function (item) {
                              dp.player.playbackRate = item.type;
                              that.changeBeishu(item.index);
                              dp.storage.set('backRate', item.type);
                              dp.storage.set('backRate_index', item.index);
                              // //判断是否二倍
                              // if (item.type == 2) {
                              //   if (that.isvip) {
                              //     dp.player.playbackRate = item.type;
                              //     that.changeBeishu(item.index);
                              //     dp.storage.set('backRate', item.type);
                              //     dp.storage.set('backRate_index', item.index);
                              //   } else if (!that.isvip) {
                              //     // that.YS.gotoVip(1, 'open', 'mustvip');
                              //     that.openandclo('mustvip', true);
                              //     var www =
                              //       document.getElementsByClassName('art-control-backR')[0]
                              //         .childNodes[0];
                              //     www.textContent = '倍数';
                              //     that.changeBeishu(3);
                              //     // dp.player.playbackRate = 1;
                              //   }
                              // } else {
                              //   dp.player.playbackRate = item.type;
                              //   that.changeBeishu(item.index);
                              //   dp.storage.set('backRate', item.type);
                              //   dp.storage.set('backRate_index', item.index);
                              // }
                            },
                          },
                          {
                            name: 'danmu',
                            position: 'right',
                            html: '',
                            index: 17,
                            click: function (e) {
                              e;
                              that.danmuCode = !that.danmuCode;
                              let danmuButton =
                                document.getElementsByClassName('art-control-danmu')[0];
                              if (that.danmuCode) {
                                danmuButton.classList.remove('art-control-danmu-close');
                                that.dp.plugins.artplayerPluginDanmuku.show();
                              } else {
                                danmuButton.classList.add('art-control-danmu-close');
                                that.dp.plugins.artplayerPluginDanmuku.hide();
                              }
                              that.dp.storage.set('danmuCode', that.danmuCode);
                            },
                            mounted: (e) => {
                              if (!that.danmuCode) {
                                e.classList.add('art-control-danmu-close');
                                that.dp.plugins.artplayerPluginDanmuku.hide();
                              }
                            },
                          },
                        ],
                        customType: {
                          m3u8: (video) => {
                            // if (that.isAndroid) {
                            var hls = new Hls({
                              p2pConfig: {
                                logLevel: false,
                                live: false, // set to false in VOD mode
                                // Other p2pConfig options provided by CDNBye
                                getStats: function (
                                  totalP2PDownloaded,
                                  totalP2PUploaded,
                                  totalHTTPDownloaded,
                                ) {
                                  that.totalP2PDownloaded = totalP2PDownloaded;
                                  that.totalP2PUploaded = totalP2PUploaded;
                                  that.totalHTTPDownloaded = totalHTTPDownloaded;
                                },
                              },
                            });
                            hls.loadSource(that.url);
                            hls.attachMedia(video);
                            that.hls = hls;
                            // if (that.islogin) that.openAutoErrorReport(); //开始计时，然后自动弹出报错反馈
                            //开启极简模式时，采用hls组件
                            if (that.minimalist) {
                              hls.on(Hls.Events.MEDIA_ATTACHED, function () {
                                // that.dp.player.play = true;
                                clearInterval(that.set2); //倒计时报错反馈
                                that.minimalistReady = true;
                                if (that.videonews.seek) {
                                  hls.media.currentTime = that.videonews.seek;
                                }
                              });
                            }

                            // }
                          },
                        },
                        //弹幕
                        plugins: [
                          artplayerPluginDanmuku({
                            // danmuku: 'https://artplayer.org/assets/sample/danmuku.xml',
                            danmuku: that.danmuku,
                            speed: 8,
                            maxlength: 50,
                            margin: [10, 55],
                            opacity: 1,
                            fontSize: 18,
                            synchronousPlayback: false,
                          }),
                        ],
                      });
                      that.dp = dp;
                      that.videodom = document.getElementsByClassName('art-video')[0];
                      // if (Hls.isSupported()) {
                      //   var hls = new Hls();
                      //   hls.loadSource(that.url);
                      //   // hls.attachMedia(that.videodom);
                      // } else if (that.videodom) {
                      //   that.videodom.src = that.url;
                      //   // hls.loadSource(that.url);
                      //   // hls.attachMedia(that.videodom);
                      // }
                      if (!Hls.isSupported()) {
                        that.videodom.src = that.url;
                      }
                      // that.hls = hls;
                      //ios添加内置字幕
                      if (that.isiOS) {
                        if (that.subtitles) {
                          that.subtitles.forEach((e) => {
                            var zimu = document.createElement('track');
                            zimu.src = e.url;
                            zimu.label = e.label;
                            that.videodom.appendChild(zimu);
                          });
                        }
                      }
                      // if (that.isAndroid) {
                      // dp.option.customType.m3u8 = function () {
                      //   var hls = new window.Hls();
                      //   hls.loadSource(that.url);
                      //   hls.attachMedia(document.getElementsByClassName('art-video')[0]);
                      // };
                      // }
                      that.dpdata = dp;

                      dp.on('ready', () => {
                        clearInterval(that.set2); //倒计时报错反馈
                        that.minimalistReady = true;
                        if (that.autoErrorReport) {
                          that.openandclo('playerror', false);
                          that.autoErrorReport = false;
                        }

                        that.resque();
                        that.mplay_clear_isok = true; //清晰度显示
                        //开启跳过片头片尾功能时
                        that.jumptitle(dp);

                        //显示隐藏字幕**********************
                        dp.setting.add({
                          html: '<span>开启/关闭字幕： </span><div id="zimusetting"><input type="range" max="1" style="width:40px;" id="zimuinput" /></div>',
                          name: 'zimu',
                          index: 16,
                        });
                        var zimuinput = document.getElementById('zimuinput');
                        var playvideo = document.getElementsByClassName('art-video-player')[0];
                        //跟据jumpzimu为设置中的input初始化
                        if (that.jumpzimu == true) {
                          zimuinput.value = '1';
                          zimuinput.classList.add('buleback');
                          playvideo.classList.add('art-subtitle-show');
                        } else if (that.jumpzimu == false) {
                          playvideo.classList.remove('art-subtitle-show');
                          zimuinput.classList.remove('buleback');
                          zimuinput.value = '0';
                        } else if (that.jumpzimu == undefined) {
                          zimuinput.value = '1';
                          zimuinput.classList.add('buleback');
                        }
                        zimuinput.addEventListener('touchend', () => {
                          that.changezimu(dp);
                        });

                        //显示隐藏字幕**********************
                        //选择字幕*********************************
                        var hstr = '<div class="zimubutton" id="zimubuttonid">';
                        if (that.subtitles) {
                          that.subtitles.forEach((e) => {
                            var htmltest =
                              '<button class="butsubtitles" data-url=' +
                              e.url +
                              ' data-symbol=' +
                              e.symbol +
                              ' value=' +
                              e.symbol +
                              '>' +
                              e.label +
                              '</button>';
                            hstr = hstr + htmltest;
                          });
                        }
                        hstr = hstr + '</div>';
                        dp.setting.add({
                          html: hstr,
                          name: 'changezimu',
                          index: 17,
                        });
                        var subtitlesdiv = document.getElementById('zimubuttonid');
                        //添加点击事件
                        subtitlesdiv.addEventListener('click', (e) => {
                          if (e.target.dataset.symbol) {
                            dp.subtitle.switch(e.target.dataset.url);
                            dp.storage.set('actsubcode', e.target.dataset.symbol);
                            that.actsubcode = e.target.dataset.symbol;
                            setTimeout(() => {
                              that.changesubtitles();
                            });
                          }
                        });
                        setTimeout(() => {
                          that.changesubtitles();
                        });
                        //选择字幕*********************************

                        //字幕偏移时间********************************

                        dp.setting.add({
                          html: '<span>字幕偏移时间:&nbsp;<span id="pianyitime" class="pianyitime">0</span>s</span><div id="subtimediv" class="subtimediv"><button data-type="less" class="subtime_lr subtime_left">-0.5s</button><button data-type="add"  class="subtime_lr subtime_right">+0.5s</button></div>',
                          name: 'subtime',
                          index: 18,
                        });
                        var subtimediv = document.getElementById('subtimediv');
                        var pianyitime = document.getElementById('pianyitime');
                        subtimediv.addEventListener('click', (e) => {
                          if (e.target.dataset.type) {
                            var type = e.target.dataset.type;
                            if (type == 'add') {
                              that.subtimechange += Number(0.5);
                            }
                            if (type == 'less') {
                              that.subtimechange -= Number(0.5);
                            }
                            dp.plugins.subtitleOffset.offset(that.subtimechange);
                            dp.notice.show = '字幕偏移  ' + that.subtimechange + ' 秒';
                            pianyitime.textContent = that.subtimechange;
                          }
                        });
                        //字幕偏移时间********************************

                        //跳过片头片尾***************************

                        var d =
                          '<div class="junph"><span>跳过片头片尾：</span><div class="clear_vip_q"></div></div><input type="range" max="1" style="width:40px;" id="tiaoinput" />';
                        dp.setting.add({
                          html: d,
                          name: 'tiao',
                          index: 19,
                        });
                        var tiaoinputdom = document.getElementById('tiaoinput');
                        if (that.jumpover == true) {
                          tiaoinputdom.value = '1';
                          tiaoinputdom.classList.add('buleback');
                        } else if (that.jumpover == false) {
                          tiaoinputdom.value = '0';
                          tiaoinputdom.classList.remove('buleback');
                        }
                        // else if (that.jumpover == true && !that.isvip) {
                        //   that.openandclo('mustvip', true);
                        //   dp.storage.set('jumpover', false);
                        // }
                        tiaoinputdom.addEventListener('touchend', () => {
                          that.changejump(dp);
                        });

                        //跳过片头片尾***************************

                        //跳过片尾*********************************
                        that.jumptail(dp);

                        // dp.player.fullscreenRotateToggle = true;

                        setTimeout(() => {
                          // // //给切换清晰度中的按钮添加绑定事件
                          // var aaa = document.getElementsByClassName('art-control-quality')[0].childNodes[1]
                          //   .childNodes;
                          // // var aaa1 = document.getElementsByClassName('mplay_c_box');
                          // aaa.forEach((e, i) => {
                          //   aaa[i].addEventListener('click', function () {
                          //     that.changequality(e, i); //获取清晰度url/验证清晰度权限
                          //   });
                          //   // ); //给他们各自添加 “1080P”这种class名,没有则把1080p替换成在qua的下标
                          //   aaa[i].classList.add('q__' + that.qua.indexOf(e.textContent) + 'q__'); //给他们各自添加 “1080P”这种class名,没有则把1080p替换成在qua的下标
                          //   aaa[i].classList.add('clear_' + that.qua.indexOf(e.textContent));
                          //   // aaa1[i].classList.add('q__' + that.qua.indexOf(e.textContent) + 'q__'); //给他们各自添加 “1080P”这种class名,没有则把1080p替换成在qua的下标
                          //   // aaa1[i].classList.add('clear_' + that.qua.indexOf(e.textContent));
                          //   // aaa[i].textContent = that.qua1[that.qua.indexOf(e.textContent)];
                          // });
                          // aaa[that.videonews.quality - 1].classList.add('clear_act');
                          try {
                            var bbb =
                              document.getElementsByClassName('art-controls-left')[0].childNodes[1];
                            bbb.className = 'next_play';
                            bbb.addEventListener('click', () => {
                              that.nextvideo();
                            });
                          } catch (error) {
                            console.log(error.message);
                          }

                          try {
                            var ccc = document.getElementsByClassName('topout')[0];
                            ccc.addEventListener('click', () => {
                              dp.player.fullscreen = false;
                            });
                          } catch (error) {
                            console.log(error.message);
                          }

                          try {
                            var eee = document
                              .getElementsByClassName('artplayer-app2')[0]
                              .getElementsByClassName('art-video')[0];
                            eee.addEventListener('click', () => {
                              // e.stopPropagation();
                              dp.player.toggle = true;
                            });
                          } catch (error) {
                            console.log(error.message);
                          }

                          try {
                            // var fff = document.getElementsByClassName('art-mask')[0];
                            var fff = document.getElementsByClassName('art-mask');
                            fff[0].addEventListener('click', () => {
                              dp.player.play = true;
                            });
                          } catch (error) {
                            console.log(error.message);
                          }

                          try {
                            var ggg = document.getElementsByClassName('art-control-pip');
                            ggg[0].addEventListener('touchend', () => {
                              if (that.isiOS && document.pictureInPictureEnabled) {
                                const videoDom = document.getElementsByClassName('art-video');
                                if (document.pictureInPictureElement) {
                                  document.exitPictureInPicture();
                                } else {
                                  if (document.pictureInPictureEnabled) {
                                    videoDom[0].requestPictureInPicture();
                                  }
                                }
                              }
                            });
                          } catch (error) {
                            console.log(error.message);
                          }

                          try {
                            if (localStorage.getItem('artplayer_settings')) {
                              var localP = JSON.parse(localStorage.getItem('artplayer_settings'));

                              if (localP.backRate && localP.backRate > 0) {
                                that.dp.player.playbackRate = localP.backRate;
                                that.changeBeishu(localP.backRate_index); //倍数选中样式
                              }
                            }
                          } catch (error) {
                            console.log(error.message);
                          }
                          //清晰度样式

                          // var div1 = document.getElementsByClassName('clear_3')[0];
                          // if (div1) {
                          //   var br = document.createElement('div');
                          //   br.className = 'clear_vip';
                          //   div1.appendChild(br);
                          // }

                          // var div2 = document.getElementsByClassName('clear_4')[0];
                          // if (div2) {
                          //   var br2 = document.createElement('div');
                          //   br2.className = 'clear_vip';
                          //   div2.appendChild(br2);
                          // }

                          that.resque();
                        }, 1000);
                        that.autoNextSet(dp);
                        that.guide = '视频准备就绪！';
                        //倍数播放
                        if (localStorage.getItem('artplayer_settings')) {
                          try {
                            var localP = JSON.parse(localStorage.getItem('artplayer_settings'));

                            if (localP.backRate && localP.backRate > 0) {
                              that.dp.player.playbackRate = localP.backRate;
                            }
                          } catch (error) {
                            console.log(error.message);
                          }
                        }

                        that.setpause = setInterval(() => {
                          if (that.dp.player.playing) {
                            that.puaseTime();
                            that.puaseNum += 1;
                          }
                          if (that.puaseNum > 240) {
                            clearInterval(that.setpause);
                          }
                        }, 1000 * 30);

                        //-----------ready-end------------
                      });

                      that.setpause = setInterval(() => {
                        if (that.dp?.player?.playing) {
                          that.puaseTime();
                          that.puaseNum += 1;
                        } else {
                          if (
                            document.getElementsByClassName('art-video-player')[0].childNodes?.[0]
                              ?.paused == false
                          ) {
                            // debugger;
                            that.puaseTime();
                          }
                        }
                        if (that.puaseNum > 240) {
                          clearInterval(that.setpause);
                        }
                      }, 1000 * 30);
                      dp.on('play', () => {});
                      //暂停时
                      dp.on('pause', function () {
                        that.puaseTime();
                        //间隔两秒则自动隐藏
                      });
                      dp.on('quality_end', function () {
                        //添加选中样式
                        var qualitymask = document.getElementsByClassName('dplayer-quality-mask');
                        qualitymask[0].classList.add('disnone');
                      });
                      // dp.on('fullscreen', () => {
                      //   dp.player.fullscreenRotateToggle = true;
                      // });
                      // // 播放事件,隐藏工具栏
                      // dp.on('playing', function () {
                      //   setTimeout(function () {
                      //     //player-con-h5 是播放器Div的Id

                      //     document.getElementById('dplayer') &&
                      //       document.getElementById('dplayer').classList.add('dplayer-hide-controller');
                      //   }, 3000);
                      // });
                    }
                  }, 300);

                  if (that.islogin) that.openAutoErrorReport(); //开始计时，然后自动弹出报错反馈
                  that.islogin
                    ? localStorage.removeItem('not_logged_history')
                    : that.YS.setHistory(that.videolist); //未登录下记录缓存
                  that.url = data.video_soruce.url; //artplayer

                  that.video_soruce = data.video_soruce;
                  //  ---start--- 往进度条插入片头片尾提示
                  // data.video_soruce.front_duration &&
                  //   that.highlight.unshift({
                  //     time: data.video_soruce.front_duration,
                  //     text: '片头',
                  //   });
                  // data.video_soruce.tail_duration &&
                  //   that.highlight.push({ time: data.video_soruce.tail_duration, text: '片尾' });
                  data.video_soruce.front_duration &&
                    that.highlight.unshift({
                      time: data.video_soruce.front_duration,
                      text: '片头结束',
                    });
                  data.video_soruce.front_start_duration &&
                    that.highlight.unshift({
                      time: data.video_soruce.front_start_duration,
                      text: '片头开始',
                    });
                  data.video_soruce.tail_duration &&
                    that.highlight.push({
                      time: data.video_soruce.tail_duration,
                      text: '片尾',
                    });
                  data.video_soruce.tail_end_duration &&
                    that.highlight.push({
                      time: data.video_soruce.tail_end_duration,
                      text: '片尾结束',
                    });
                  //  ---end--- 往进度条插入片头片尾提示
                  //ios不要外挂字幕
                  // if (!this.isiOS) {
                  // that.options.subtitle.url = data.video_soruce.subtitle;
                  // }
                  that.subtitles = data.subtitles;
                  //获取本地存储的跳过片头片尾状态//获取本地存储的选择字幕状态

                  if (that.locitem) {
                    that.jumpover =
                      that.locitem.jumpover != undefined ? that.locitem.jumpover : false;
                    that.jumpzimu =
                      that.locitem.jumpzimu != undefined ? that.locitem.jumpzimu : true;
                    that.actsubcode = that.locitem.actsubcode ? that.locitem.actsubcode : '';
                  }
                  //遍历循环查找默认的字幕
                  if (data.subtitles) {
                    if (that.actsubcode) {
                      //遍历查找是否存在储存的字幕
                      that.subtitles.forEach((e) => {
                        if (e.symbol == that.actsubcode) {
                          that.options.subtitle.url = e.url;
                          that.subact = e.symbol;
                        }
                      });
                    }
                    if (!that.subact) {
                      that.subtitles.forEach((e) => {
                        if (e.default == 1) {
                          that.options.subtitle.url = e.url;
                          that.subact = e.symbol;
                        }
                      });
                    }
                  }

                  if (data.qualities) {
                    //清晰度重新排序
                    let quaaaa = [];
                    for (let i = 1; i <= 4; i++) {
                      if (data.qualities.indexOf(i) >= 0) {
                        quaaaa.push(i);
                      }
                    }

                    // if (quaaaa.indexOf(that.videonews.quality) < 0) {
                    //   1;
                    //   that.videonews;
                    // }
                    // debugger;

                    data.qualities = quaaaa;
                    data.qualities.forEach((e, i) => {
                      that.options.video.quality[i] = {};
                      that.options.video.quality[i].name = that.qua[e];
                      if (e == data.video_soruce.quality) {
                        that.options.video.quality[i].url = data.video_soruce.url;
                        // that.options.video.quality[i].url = require('../assets/text/textplay.mp4');
                        that.options.video.quality[i].type = 'auto';
                        that.options.video.url = data.video_soruce.url;
                      } else {
                        that.options.video.quality[i].url = data.video_soruce.url;
                        // that.options.video.quality[i].url = require('../assets/text/textplay.mp4');
                        that.options.video.quality[i].type = 'auto';
                      }
                    });
                    that.mplay_clear_isok = true;
                    //匹配不到本地存储的清晰度状态时
                    if (data.qualities.indexOf(Number(that.videonews.quality)) < 0) {
                      that.videonews.quality = 1;
                    }
                  }
                })
                .catch((error) => {
                  if (error.message !== "Cannot read property 'subtitle' of null")
                    that.usepubilDialogError(error.message, '');
                  that.guideError += 'api_videosource接口阶段出错';
                });
            }
            this.video_fragment_list.forEach((e, i) => {
              if (e.id == that.videonews.video_fragment_id) {
                e.act = true;
                that.nowVideoIndex = i + 1;
                setTimeout(() => {
                  var num = Math.floor(that.total_epi / 20.1);
                  // that.YS.actgo('mplay_lt_list', 'mplay_lt_list-act', num);
                  // that.ltindex = num;
                  // that.video_fragmen_act = Math.floor(that.nowVideoIndex / 20.1);
                  that.ltindex = Math.floor(that.nowVideoIndex / 20.1);
                  that.cliSettitle(that.ltindex);
                  that.video_fragmen_act = num;
                }, 300);
              }
            });
            // that.p_d = true;
          }
        })
        .catch((error) => {
          that.guideError += 'videoinfo接口阶段出错！';
          that.usepubilDialogError(error.message, '');
        });
    },
    // 切换下一集
    nextvideo() {
      var nowid = parseInt(this.videonews.video_fragment_id);
      var nextvideoindex;
      that.video_fragment_list.forEach((e, i) => {
        if (e.id == nowid) {
          nextvideoindex = i;
        }
      });
      // var nextvideoid = that.video_fragment_list[nextvideoindex + 1].id;
      // that.YS.routetogo({
      //   path: '/mplay',
      //   query: { video_id: this.videonews.video_id, lid: nextvideoid },
      // });

      //判断是否有下一集
      if (that.video_fragment_list[nextvideoindex + 1]) {
        var nextvideoid = that.video_fragment_list[nextvideoindex + 1].id;
        that.YS.routetogo({
          path: '/mplay',
          query: { video_id: this.videonews.video_id, lid: nextvideoid },
        });
      } else if (that.lastListCode && nextvideoindex) {
        that.lastListCode = false;
        that.usepubilDialogError('没有下一集', '');
      }
      //this.routerRefresh(); //调用app.vue里面的routerRefresh()方法，完成摧毁和重建过程
    },
    //获取弹幕
    async getdanmuku() {
      try {
        let data = await that.api.user.api_videoBarragelist({
          video_id: that.videonews.video_id,
          video_fragment_id: that.videonews.video_fragment_id,
        });
        // let data2 = await that.api.user.api_videoBarragepostBarrage({
        //   video_id: that.videonews.video_id,
        //   video_fragment_id: that.videonews.video_fragment_id,
        //   elapsed: 1,
        // });

        if (!data || !data.videoBarrage_list) return;
        that.danmuku = data.videoBarrage_list;
      } catch (error) {
        console.log(error.message);
      }
    },
    // 点击收藏视频按钮
    async clifavorite() {
      try {
        if (that.$store.state.islogin) {
          //已登录
          if (that.in_favorite) {
            // 已经收藏的情况
            //发送取消收藏
            await that.api.user.api_videodeleteFavorite({
              video_id: that.videonews.video_id,
            });
            that.in_favorite = 0;
            // that.usepubilDialog('已取消收藏');
          } else {
            // 未收藏的情况
            //发送收藏视频
            await that.api.user.api_videofavorite({ video_id: that.videonews.video_id });
            that.in_favorite = 1;
            that.usepubilDialog('收藏成功');
          }
        } else {
          //未登录
          that.openandclo('mustlogin', true);
        }
      } catch (error) {
        console.log(error);
      }
    },
    //点击发表评论
    clipinglun() {
      that.pldialog = true;
    },
    //发表评论事件
    async videopostComment() {
      try {
        await that.api.user.api_videopostComment({
          video_id: that.videonews.video_id,
          content: that.textcontent,
        });
        that.usepubilDialog('评价成功');
        that.videocomment();
        that.pldialog = false;
      } catch (error) {
        that.usepubilDialogError(error.message, '');
      }
    },
    //评论列表
    async videocomment() {
      try {
        let data = await that.api.user.api_videocomment({
          video_id: that.videonews.video_id,
        });
        that.video_comment_total = data.video_comment_total;
        that.video_comment_list = data.video_comment_list;
      } catch (error) {
        console.log(error);
      }
    },
    // 广告栏点击
    guanggaocli(url) {
      // window.location.href = url;
      window.open(url);
    },
    // 获取广告
    async adall(symbols) {
      try {
        let data = await that.api.user.api_adall({ symbols: symbols });

        if (data) {
          that.guanggaolist[symbols] = data[symbols];
        }
      } catch (error) {
        console.log(error);
      }
    },
    //点击视频详情
    clickxq() {
      that.p_d = !that.p_d;
    },
    openandclo(type, boo) {
      clearInterval(that.set2);
      that[type] = boo;
      var coverm = document.querySelector('#coverm');
      if (coverm && coverm.style) {
        if (boo || that.mustvip) {
          coverm.style.display = 'block';
          try {
            if (!that.minimalist) that.dp.player.fullscreen = false;
          } catch (error) {
            console.log(error.message);
          }
        } else {
          coverm.style.display = 'none';
        }
      }
    },
    //打开需要vip弹窗
    openmustvip() {
      clearInterval(that.set2);
      that.mustvip = true;
      var coverm = document.querySelector('#coverm');
      coverm.style.display = 'block';
    },
    //打开需要vip弹窗
    openmustlogin() {
      that.mustlogin = true;
      var coverm = document.querySelector('#coverm');
      coverm.style.display = 'block';
    },
    //需要vip  点击前往开通vip
    climustvip() {
      var coverm = document.querySelector('#coverm');
      coverm.style.display = 'none';
      that.YS.routetogo({ path: '/mvip' });
    },
    //需要登录  点击去登录
    climustlogin() {
      var coverm = document.querySelector('#coverm');
      coverm.style.display = 'none';
      var localNow = {};
      localNow.path = that.$route.path;
      localNow.query = that.$route.query;
      localStorage.setItem('mloginAfter', JSON.stringify(localNow));
      that.YS.routetogo({ path: '/mlogin' });
    },
    //切换清晰度失败时复位
    resque(a) {
      setTimeout(() => {
        if (!a) {
          var num = 0;
        } else {
          num = a;
        }
        var qu =
          document.getElementsByClassName('art-controls-right')[0].childNodes[num].childNodes[0];
        if (that.qua.indexOf(qu.textContent) > 0 || that.qua1.indexOf(qu.textContent) > 0) {
          qu.textContent = that.qua[that.videonews.quality].split('·')[0];
          document.getElementsByClassName('art-control-quality')[0].style.visibility = 'visible';
        } else {
          if (num < 10) that.resque(num + 1);
        }
      });
    },
    //开局获取并检查校验清晰度
    getAndCheckquer() {
      var qialityloc = that.locitem ? that.locitem.qialityloc : '';
      //开局获取清晰度并校验
      if (qialityloc) {
        //存在本地存储时，以地址栏参数为优先,地址栏不指定参数则调用本地存储参数
        if (this.$route.query.quality) {
          this.videonews.quality = this.$route.query.quality;
        } else {
          this.videonews.quality = qialityloc;
        }
      } else {
        //不存在本地存储清晰度时，优先获取地址栏参数

        this.videonews.quality = this.$route.query.quality ? this.$route.query.quality : 1;
      }
      //清晰度权限校验
      if (this.videonews.quality > 1) {
        //未登录时
        if (!that.$store.state.islogin) {
          this.videonews.quality = 1;
          // if (this.videonews.quality > 2) {
          //   that.openandclo('mustvip', true);
          // }
          if (this.videonews.quality == 2) {
            that.openandclo('mustlogin', true);
          }
        }
        //  else {
        //   //登录时
        //   if (this.videonews.quality > 2 && !that.isvip) {
        //     this.videonews.quality = 1;
        //     that.openandclo('mustvip', true);
        //     if (localStorage.getItem('artplayer_settings')) {
        //       var localitem = JSON.parse(localStorage.getItem('artplayer_settings'));
        //       if (localitem.qialityloc) {
        //         localitem.qialityloc = this.videonews.quality;
        //         localStorage.setItem('artplayer_settings', JSON.stringify(localitem));
        //       }
        //     }
        //   }
        // }
      }
    },
    changequality(e, i) {
      var hls = that.hls;
      var dp = that.dp;
      var ec = e.className || e.currentTarget.className;
      var et = e.textContent || e.currentTarget.textContent;
      // if (et.indexOf('会员')) {
      //   debugger;
      //   et = et.split('/会员')[1];
      // }
      if (ec.split('q__')[1]) {
        et = that.qua[ec.split('q__')[1]];
      }
      var aaa = document.getElementsByClassName('art-control-quality')[0].childNodes[1].childNodes;
      // that.qua.indexOf(e.innerHTML)>=3,是找不到当前清晰度会变成null，indexof则为-1，
      if (!that.$store.state.islogin) {
        //没登陆时候
        // if (that.qua.indexOf(et) >= 3) {
        //   //选中超清蓝光
        //   dp.player.fullscreen = false;
        //   that.openandclo('mustvip', true);
        //   that.resque();
        // } else
        if (that.qua.indexOf(et) >= 2) {
          //选中标清高清
          dp.player.fullscreen = false;
          that.openandclo('mustlogin', true);
          that.resque();
        }
      } else if (!that.$store.state.islogin && that.qua.indexOf(et) >= 2) {
        that.openandclo('mustlogin', true);
        that.resque();
      } else {
        that.videonews.quality = ec.split('q__')[1];
        if (that.isiOS) {
          that.videonews.seek = dp.player.currentTime;
          var videoIos = that.videonews;
          videoIos.lid = that.videonews.video_fragment_id;
          that.YS.routetogo({ path: '/mplay', query: videoIos });
          //this.routerRefresh();
        }
        // 一、样式选择添加
        aaa.forEach((item) => {
          item.classList.remove('clear_act');
        });
        aaa[i].classList.add('clear_act');
        // 二、匹配后端的清晰度标识，（后端是从1开始）
        // et;
        // debugger;
        // that.videonews.quality = parseInt(e.className) + 1;
        //给每一个清晰度按钮添加class，跟据class判断按钮的下标顺序

        // 三、向后端发送接口，获取清晰度的url信息
        that.api.user.api_videosource(that.videonews).then((data) => {
          dp.storage.set('qialityloc', data.video_soruce.quality); //记录本地存储
          var actname = that.qua[that.videonews.quality];
          if (that.options.video.quality) {
            that.options.video.quality.forEach((e, i) => {
              if (e.name == actname) {
                that.qact = i;
                e = data.video_soruce;
                e.url = data.video_soruce.url;
                e.subtitle = data.video_soruce.subtitle;
                e.name = that.qua[data.video_soruce.quality];
                that.url = data.video_soruce.url;
                that.videonews.quality = i + 1;
                that.qualityName = that.qua[data.video_soruce.quality];
              }
            });
          }

          // debugger;
          // that.options.video.quality[that.videonews.quality - 1] = data.video_soruce;
          // that.options.video.quality[that.videonews.quality - 1].url = data.video_soruce.url;
          // that.options.video.quality[that.videonews.quality - 1].subtitle =
          //   data.video_soruce.subtitle;
          // that.options.video.quality[that.videonews.quality - 1].name =
          //   that.qua[data.video_soruce.quality];
          // that.url = data.video_soruce.url; //artplayer
          //这里需要手动调用切换清晰度方法（修改了插件，删除了插件中的方法）
          // -1从后端的清晰度下标转化成dplayer插件的清晰度下标
          // dp.player.switchUrl(that.url);

          dp.player.switchQuality(
            // that.url,
            data.video_soruce.url,
            that.options.video.quality[that.videonews.quality - 1].name,
          );
          hls.loadSource(that.url);
          hls.attachMedia(document.getElementsByClassName('art-video')[0]);

          //切换字幕
          // dp.subtitle.switch(data.video_soruce.subtitle);

          that.resque();
        });
      }
    },
    cliquality(e) {
      // var video = document.getElementById('dplayer');
      // video.currentTime;
      // that.YS.routetogo({
      //   path: '/mplay',
      //   query: { video_id: that.videonews.video_id, lid: that.videonews.video_fragment_id },
      // });

      var hls = that.hls;
      // var dp = that.dp;
      var ec = e.className || e.currentTarget.className;
      var et = e.textContent || e.currentTarget.textContent;

      if (ec.split('q__')[1]) {
        et = that.qua[ec.split('q__')[1]];
      }
      // that.qua.indexOf(e.innerHTML)>=3,是找不到当前清晰度会变成null，indexof则为-1，
      if (!that.$store.state.islogin) {
        //没登陆时候
        // if (that.qua.indexOf(et) >= 3) {
        //   //选中超清蓝光
        //   that.openandclo('mustvip', true);
        // } else
        if (that.qua.indexOf(et) >= 2) {
          //选中标清高清
          that.openandclo('mustlogin', true);
        }
      }
      // else if (
      //   Date.parse(new Date()).toString().substr(0, 10) > that.$store.state.user.vip_expired_time &&
      //   that.qua.indexOf(et) >= 3
      // ) {
      //   that.openandclo('mustvip', true);
      // }
      else if (!that.$store.state.islogin && that.qua.indexOf(et) >= 2) {
        that.openandclo('mustlogin', true);
      } else {
        that.videonews.quality = ec.split('q__')[1];
        that.videonews.seek = Math.floor(hls.media.currentTime);
        var videoIos = that.videonews;
        videoIos.lid = that.videonews.video_fragment_id;
        that.YS.routetogo({ path: '/mplay', query: videoIos });

        // // 三、向后端发送接口，获取清晰度的url信息
        // that.api.user.api_videosource(that.videonews).then((data) => {
        //   var actname = that.qua[that.videonews.quality];
        //   if (that.options.video.quality) {
        //     that.options.video.quality.forEach((e, i) => {
        //       if (e.name == actname) {
        //         that.qact = i;
        //         e = data.video_soruce;
        //         e.url = data.video_soruce.url;
        //         e.subtitle = data.video_soruce.subtitle;
        //         e.name = that.qua[data.video_soruce.quality];
        //         that.url = data.video_soruce.url;
        //         that.videonews.quality = i + 1;
        //         that.qualityName = that.qua[data.video_soruce.quality];
        //       }
        //     });
        //   }
        // });
      }
    },
    //跳过片头
    jumptitle(dp) {
      // if (!!that.jumpover && !!that.video_soruce.front_duration && that.jumpover != 0) {
      //   if (
      //     that.video_soruce.front_duration &&
      //     dp.player.currentTime < that.video_soruce.front_duration
      //   ) {
      //     dp.player.seek = that.video_soruce.front_duration;
      //     dp.notice.show = '尊敬的VIP会员，已经为您自动跳过片头。';
      //   }
      // } else {
      //   that.videonews.seek && (dp.player.seek = that.videonews.seek);
      // }
      if (!!that.jumpover && !!that.video_soruce.front_duration && that.jumpover != 0) {
        if (
          that.video_soruce.front_duration &&
          dp.player.currentTime < that.video_soruce.front_duration &&
          !that.video_soruce.front_start_duration
        ) {
          //没有片头开始参数时
          dp.player.seek = that.video_soruce.front_duration;
          dp.notice.show = '尊敬的VIP会员，已经为您自动跳过片头。';
        }
      } else {
        //存在跳转参数时跳转（地址栏）
        that.videonews.seek && (dp.player.seek = that.videonews.seek);
      }
    },
    //跳过片尾
    // jumptail(dp) {
    //   if (that.video_soruce.tail_duration && that.jumpover != 0) {
    //     var videodom = document.getElementsByClassName('art-video')[0];
    //     videodom.addEventListener('timeupdate', () => {
    //       var timeDisplay = Math.floor(dp.player.currentTime);
    //       // console.log(timeDisplay);
    //       if (timeDisplay > that.video_soruce.tail_duration - 5) {
    //         dp.notice.show = '尊敬的VIP会员，5秒后为您跳转下一集';
    //       }
    //       if (timeDisplay > that.video_soruce.tail_duration) {
    //         // dp.notice.show = '尊敬的VIP会员，到达片尾，为您跳转下一集';
    //         that.nextvideo();
    //       }
    //     });
    //   }
    // },
    //跳过片尾
    jumptail(dp) {
      if (
        (that.video_soruce.tail_duration || that.video_soruce.front_duration) &&
        that.jumpover != 0
      ) {
        var videodom = document.getElementsByClassName('art-video')[0];
        videodom.addEventListener('timeupdate', () => {
          var timeDisplay = Math.floor(dp.player.currentTime);
          // console.log(timeDisplay);
          //跳过片头
          if (
            that.video_soruce.front_start_duration &&
            timeDisplay > that.video_soruce.front_start_duration &&
            timeDisplay < that.video_soruce.front_duration - 3
          ) {
            dp.player.seek = that.video_soruce.front_duration;
            dp.notice.show = '尊敬的VIP会员，已经为您自动跳过片头。';
          }
          //存在片尾end时，跳过片尾
          if (
            that.video_soruce.tail_duration &&
            that.video_soruce.tail_end_duration &&
            timeDisplay > that.video_soruce.tail_duration &&
            timeDisplay < that.video_soruce.tail_end_duration
          ) {
            dp.player.seek = that.video_soruce.tail_end_duration;
            dp.notice.show = '尊敬的VIP会员，已经为您自动跳过片尾。';
          }
          //接上，之后自动下一集
          if (
            that.video_soruce.tail_duration &&
            that.video_soruce.tail_end_duration > 0 &&
            timeDisplay > that.video_soruce.tail_end_duration &&
            timeDisplay > Math.floor(dp.player.duration) - 5
          ) {
            dp.notice.show = '尊敬的VIP会员，即将结束播放，5秒后将自动为您播放下一集';
          }
          if (
            that.video_soruce.tail_end_duration > 0 &&
            timeDisplay > that.video_soruce.tail_end_duration &&
            timeDisplay + 1 > Math.floor(dp.player.duration)
          ) {
            dp.notice.show = '尊敬的VIP会员，为您跳转下一集';
            that.nextvideo();
          }
          //不存在片尾end时，播放下一集
          if (
            that.video_soruce.tail_duration &&
            !that.video_soruce.tail_end_duration &&
            timeDisplay > that.video_soruce.tail_duration - 5
          ) {
            dp.notice.show = '尊敬的VIP会员，即将结束播放，5秒后将自动为您播放下一集';
          }
          if (
            that.video_soruce.tail_duration &&
            !that.video_soruce.tail_end_duration &&
            timeDisplay + 1 > that.video_soruce.tail_duration
          ) {
            // dp.notice.show = '到达片尾，为您跳转下一集';
            that.video_soruce.tail_duration = 9999999999;
            that.nextvideo();
          }
          if (dp.player.currentTime + 1 > dp.player.duration) {
            // dp.notice.show = '到达片尾，为您跳转下一集';
            that.video_soruce.tail_duration = 9999999999;
            that.nextvideo();
          }
        });
      }
    },
    //改变片头片尾按钮
    changejump(dp) {
      var tiaovalue = document.getElementById('tiaoinput');
      // if (!that.isvip) {
      //   tiaovalue.value = '0';
      //   tiaovalue.classList.remove('buleback');
      //   return that.openandclo('mustvip', true);
      // }

      //点击时未改变值
      that.jumpover = !that.jumpover;
      if (that.jumpover) {
        tiaovalue.value = '1';
        tiaovalue.classList.add('buleback');
      } else {
        tiaovalue.value = '0';
        tiaovalue.classList.remove('buleback');
      }
      dp.storage.set('jumpover', that.jumpover);

      //执行跳过片尾方法
      if (that.jumpover) {
        //开启跳过片头,片尾
        that.jumptitle(dp);
        that.jumptail(dp);
        dp.notice.show = '开启跳过片头片尾功能。';
      } else {
        //关闭跳过片头片尾
        //******************************* */
        that.video_soruce.tail_duration = 999999999999999999999;
        dp.notice.show = '关闭跳过片头片尾功能。';
      }
    },
    //自动下一集
    autoNextSet(dp) {
      var nowid = parseInt(this.videonews.video_fragment_id);
      var nextvideoindex;
      that.video_fragment_list.forEach((e, i) => {
        if (e.id == nowid) {
          nextvideoindex = i;
        }
      });
      if (that.video_fragment_list && that.video_fragment_list[nextvideoindex + 1]) {
        var videodom = document.getElementsByClassName('art-video')[0];
        videodom.addEventListener('timeupdate', () => {
          // console.log(timeDisplay);
          if (
            Math.floor(dp.player.currentTime) > that.dp.player.duration - 8 &&
            that.dp.player.duration > 1
          ) {
            dp.notice.show = '视频即将结束，即将为您跳转下一集';
          }
          if (
            Math.floor(dp.player.currentTime) > that.dp.player.duration - 3 &&
            that.dp.player.duration > 1
          ) {
            // dp.notice.show = '到达片尾，为您跳转下一集';
            that.nextvideo();
          }
        });
      }
    },
    //发送暂停接口
    async puaseTime() {
      try {
        let play_this = null;
        play_this;
        if (that.dp.player?.currentTime) {
          play_this = that.dp.player;
        } else {
          play_this = document.getElementsByClassName('art-video-player')[0].childNodes?.[0];
        }
        if (
          that.islogin &&
          !(
            Math.floor(play_this.currentTime) > that.puaseCurrrent - 5 &&
            Math.floor(play_this.currentTime) < that.puaseCurrrent + 5
          )
        ) {
          let stoplist = {};
          stoplist.video_id = that.videonews.video_id;
          stoplist.video_fragment_id = that.videonews.video_fragment_id;
          // stoplist.quality = that.videonews.quality;
          stoplist.duration = Math.floor(play_this.duration);
          stoplist.elapsed = Math.floor(play_this.currentTime);

          await that.api.user.api_userupdateHistory(stoplist);
          that.puaseCurrrent = stoplist.elapsed;
        }
      } catch (error) {
        console.log(error);
      }
    },
    //开关字幕按钮
    changezimu(dp) {
      var zimuinput = document.getElementById('zimuinput');
      var playvideo = document.getElementsByClassName('art-video-player')[0];
      //点击时未改变值
      //取相反值

      that.jumpzimu = !that.jumpzimu;
      if (that.jumpzimu) {
        zimuinput.value = 1;
        zimuinput.classList.add('buleback');
        playvideo.classList.add('art-subtitle-show');

        dp.notice.show = '开启字幕显示。';
      } else {
        zimuinput.value = 0;
        zimuinput.classList.remove('buleback');
        playvideo.classList.remove('art-subtitle-show');
        dp.notice.show = '关闭字幕显示。';
      }

      dp.storage.set('jumpzimu', that.jumpzimu);
    },
    //多选字幕选中样式
    changesubtitles() {
      var subtitlesdiv = document.getElementById('zimubuttonid');
      subtitlesdiv.childNodes.forEach((ele) => {
        if (ele.dataset.symbol == that.actsubcode) {
          ele.classList.add('bulebackbor');
        } else {
          ele.classList.remove('bulebackbor');
        }
      });
    },
    //添加class和插入dom
    domClassAdd() {},
    //切换倍数选中样式
    async changeBeishu(index) {
      try {
        var beishuList = document.getElementsByClassName('art-control-backR')[0].childNodes[1];
        beishuList.childNodes.forEach((e) => {
          e.classList.remove('beishu_act');
        });
        beishuList.childNodes[index].classList.add('beishu_act');
      } catch (error) {
        console.log(error.message);
      }
    },

    openAutoErrorReport() {
      var qwe = 0;
      that.set2 = setInterval(() => {
        if (that.hls.media.duration) {
          debugger;
          that.minimalistReady = true;
          clearInterval(that.set2); //倒计时报错反馈
          if (that.autoErrorReport) {
            that.openandclo('playerror', false);
            that.autoErrorReport = false;
          }
        }
        qwe += 1;
        if (qwe >= 15) {
          that.autoErrorReport = true;
          that.clifeedback();
          clearInterval(that.set2); //倒计时报错反馈
        }
      }, 1001); //倒计时报错反馈
    },
    //点击关闭广告
    advertisingClose() {
      that.advertising_ready = false;
      clearInterval(that.set_ad);
      that.advertising_video.destroy();
      that.advertising_loading = true;
      that.dp.player.play = true;
    },
  },
};
</script>
<style lang="scss" scope>
html {
  font-family: Arial, Microsoft YaHei, 黑体, 宋体, sans-serif !important;
}
.dplayer-controller .dplayer-icons .dplayer-quality .dplayer-quality-list {
  padding: 0 !important;
}
.dplayer-quality-item {
  margin-top: 0;
}
.art-video-player {
  width: 100% !important;
  height: 100% !important;
}
.clarity_openvip {
  margin-bottom: 6px;
}
.artplayer-app2,
.artplayer-app3 {
  height: 56.25vw !important;
  width: 100vw !important;
  position: relative;
  background: #0f0f1e;
  // margin-left: -54px !important;
}
.mplay_player {
  position: relative;
}
.artplayer-app3 {
  position: absolute;
  top: 0;
  // z-index: 21;
}
.next_play {
  cursor: pointer;
  height: 36px;
  width: 36px;
  mask-image: url('../../assets/img/play_next.svg') !important;
  mask-position: center;
  mask-repeat: no-repeat;
  mask-size: 36% !important;
  background-color: white !important;
}
.clear_act {
  // border: 1px solid #ff8800 !important;
  // margin: 0 6px;
  border-radius: 4px;
  font-size: 12px !important ;
}
.clear_3 {
  position: relative;
}
.clear_4 {
  position: relative;
}

.art-control-quality {
  visibility: hidden;
}
.topout {
  width: 24px;
  height: 24px;
  position: absolute;
  top: -86vh;
  // background: rgb(255 0 0 / 55%);
  // mask-position: center;
  // mask-repeat: no-repeat;
  // mask-size: auto;
  // mask-image: url(../../assets/img/fanhui.svg);
  background-image: url('../../assets/img/fanhui.png');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  // background-color: white;
}
.art-video-player .art-bottom .art-controls .art-control-onlyText {
  padding: 0 !important;
}
.art-control-quality {
  display: none;
}
.art-video-player .art-bottom .art-controls .art-control {
  min-width: 30px !important;
}
.art-video-player .art-bottom .art-controls .art-control .art-icon {
  width: 30px !important;
}
.art-video-player .art-settings .art-setting-inner {
  width: 280px !important;
  background: rgba(28, 29, 48, 0.9) !important;
  // opacity: 0.9 !important;
}
.art-video-player .art-settings .art-setting-inner .art-setting-body .art-setting {
  border: none !important;
}

.art-setting-radio {
  .art-radio-item button {
    width: 70px !important;
    border: 1px solid #ffffff !important;
    background: #333333 !important;
    border-radius: 4px;
  }
  .current button {
    border: 1px solid #149bec !important;
    background: #149bec !important;
  }
}
.art-setting-aspectRatio {
  padding: 10px 5px 10px 15px !important;
  margin-left: -2px !important;
}
.art-setting-zimu {
  display: flex;
  flex-direction: column;
  align-items: flex-start !important;
  span {
    margin-bottom: 6px;
  }
}
.art-setting-changezimu {
  padding: 0 5px 0 15px !important;
}
.art-setting-tiao {
  display: flex;
  flex-direction: column;
  align-items: flex-start !important;
}
.art-setting-subtitleOffset {
  display: none !important;
}
.m_total {
  .art-notice {
    top: 60%;
    align-items: center;
    justify-content: center;
    transition: all 0.3s;
    // opacity: 0.33;
  }
  .art-notice-inner {
    height: 23px !important;
    border-radius: 12px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    padding: 5px 20px 3px 20px !important;
    transition: all 0.3s;
    font-size: 12px;
    font-weight: 400;
  }
}
.art-mask {
  pointer-events: auto !important;
  cursor: pointer;
}
.art-control-backR .art-selector-list :nth-child(1) {
  background-image: url('../../assets/img/video_vip.png');
  background-position: right;
  background-repeat: no-repeat;
  justify-content: flex-end;
}
.beishu_act {
  background: #2bb2ff;
}
.m_total {
  .art-progress {
    padding: 0 0 !important;
  }
  .art-control-progress {
    height: 14px !important;
  }
  .art-bottom {
    height: 108px !important;
  }
}
.play_advertising_time_m {
  display: flex;
  flex-direction: row;
  position: absolute;
  right: 10px;
  top: 24px;
  background: rgba(37, 37, 37, 0.7);
  z-index: 20;
  justify-content: center;
  align-items: center;
  .adv_close {
    mask-image: url('../../assets/img/ad_close.svg');
    height: 12px;
    width: 12px;
    background-color: white;
    -webkit-mask-position: center;
    -webkit-mask-size: initial;
    -webkit-mask-repeat: no-repeat;
    padding: 6px;
    cursor: pointer;
    &:hover {
      background-color: #ff9800;
      transition: 0.3s;
    }
  }
  .adv_nodes {
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 14px;
    padding-left: 12px;
    padding-right: 5px;
    border-left: white solid 1px;
    cursor: pointer;
    line-height: 14px;
  }
  span {
    color: #ff9800;
    font-size: 14px;
    padding: 0 5px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.art-control-danmu {
  background-repeat: no-repeat;
  // background-size: contain;
  background-size: contain;
  background-position: center;
  // min-height: unset !important;
  // height: 16px !important;
  // width: 16px !important;

  background-image: url('../../assets/img/danmu_open.png');
}
.art-control-danmu-open {
  background-image: url('../../assets/img/danmu_open.png');
}
.art-control-danmu-close {
  background-image: url('../../assets/img/danmu_close.png');
}
.art-video-player
  .art-bottom
  .art-progress
  .art-control-progress
  .art-control-progress-inner
  .art-progress-played,
.art-video-player
  .art-bottom
  .art-progress
  .art-control-progress:hover
  .art-control-progress-inner
  .art-progress-indicator,
.art-video-player
  .art-bottom
  .art-progress
  .art-control-progress:hover
  .art-control-progress-inner
  .art-progress-tip,
.art-video-player
  .art-bottom
  .art-progress
  .art-control-progress
  .art-control-progress-inner
  .art-progress-indicator.art-show-indicator {
  background: rgb(20, 155, 236) !important;
}
.art-video-player .art-bottom .art-progress .art-control-progress .art-control-progress-inner {
  height: 22% !important;
}
#dplayer3 .art-control-playAndPause {
  display: none;
}
.pleaseLogin {
  position: absolute;
  left: calc(50vw - 60px);
  height: 40px;
  width: 120px;
  top: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  background: linear-gradient(134deg, #6acbe9 0%, #149bec 100%);
  color: white;
  font-weight: bold;
}
</style>
